
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiChannelProviderDetail, apiChannelProviderAdd, apiChannelProviderEdit, apiSetMealLists,apiChannelProvideGradeList } from '@/api/channel_provider'
import historyMeal from './components/history-meal.vue'


@Component({
  components: {
    historyMeal
  }
})
export default class ListsDetail extends Vue {
  /** S Data **/

  identity: number | null = null; // 当前编辑用户的身份ID

  setMealData: any = []
  loading: boolean = false
  levelData: any = []

  // 添加渠道商表单数据
  form: any = {
    name: '', // 名称
    super_admin: '', // 账号
    super_password: '', // 密码
    super_password_confirm: '', // 确认密码
    status: 1, // 渠道商状态 0-停止服务 1-开启服务
    contact: '', // 联系人
    contact_mobile: '', // 联系人手机号
    domain_alias: '', // 域名别名
    is_check_domain: 1, // 是否验证域名
    level_id:'',
    shop_url: '', // 当前域名
    set_meal_id: '', // 套餐ID
    expires_time: '', // 到期时间
    remark: '', // 备注
    shop_data: {}
  };

  // 表单校验
  rules = {
    name: [{ required: true, message: '请输入渠道商名称', trigger: 'blur' }],
    level_id: [{ required: true, message: '请选择渠道商等级', trigger: 'blur' }],
    super_admin: [
      { required: true, message: '请输入渠道商名称', trigger: 'blur' }
    ],
    super_password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          !value ? callback(new Error('请输入密码')) : callback()
        },
        trigger: 'blur'
      }
    ],
    super_password_confirm: [
      { required: true, message: '请再次输入密码', trigger: 'blur' },
      {
        validator: (rule: object, value: string, callback: any) => {
          if (this.form.password) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.password) { callback(new Error('两次输入密码不一致!')) }
          }
          callback()
        },
        trigger: 'blur'
      }
    ],
    set_meal_id: [{ required: true, message: '请选择套餐', trigger: ['blur', 'change'] }],
    expires_time: [{ required: true, message: '请选择到期时间', trigger: ['blur', 'change'] }]
  };

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate(async (valid: boolean): Promise<any> => {
      if (!valid) return
      try{
        this.loading = true;
      if (!this.identity) {
        await  this.handleChannelProviderAdd()
      } else {
        await this.handleChannelProviderEdit()
      }
    
      }finally{
        this.loading = false;
      }
    })
  }

  // 添加渠道商
  async handleChannelProviderAdd(): Promise<void> {
    await apiChannelProviderAdd({ ...this.form })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑渠道商
  async handleChannelProviderEdit(): Promise<void> {
    await apiChannelProviderEdit({ ...this.form, id: this.identity })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getChannelProviderDetailFunc(): Promise<void> {
    const res: any = await apiChannelProviderDetail({
      id: this.identity as number
    })
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
  }

  // 获取套餐列表
  async getSetMealLists(): Promise<void> {
    const { lists } = await apiSetMealLists({ status: 1 })
    this.setMealData = lists
  }
  // 获取套餐列表
  async getLevelLists(): Promise<void> {
    const { lists } = await apiChannelProvideGradeList({ })
    this.levelData = lists
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getChannelProviderDetailFunc()
    }

    this.getSetMealLists()
    this.getLevelLists()
  }
  /** E Life Cycle **/
}
